/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        function equalizeSidebarContent() {
          // Set content height to sidebar if it's shorter.
          var sidebarHeight = $('.sidebar').outerHeight(),
              contentHeight = $('#content').outerHeight();
          if (sidebarHeight > contentHeight) {
            $('#content').height(sidebarHeight);
          }
        }


        $('ul.locations li').each(function(){
          $(this).find('.state').click(function(){
            $(this).parent().toggleClass('active');
            equalizeSidebarContent();
          });
        });

        var viewportWidth = $(window).width();
        if (viewportWidth > 767) {
          // $('.sidebar').stickyMojo({footerID: '#footer', contentID: '#main'});
        }

        // Set all anchor links to smooth scroll.
        $(function() {
          $('a[href*=#]:not([href=#])').click(function() {
            if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html,body').animate({
                  scrollTop: target.offset().top
                }, 1000);
                return false;
              }
            }
          });
        });

        // Homepage Friends of Tools Slider
        $('.friends-logos').slick({
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          autoplay: true,
          autoplaySpeed: 3000,
        });

        // Append block below login form.
        $('#loginform .login-submit').append('<a href="/trouble-signing-in/" class="btn btn-primary">eTools Login Help</a>');
        $('#loginform').append('<div class="form-footer"><div class="left"></div><div class="right"></div></div>');
        var formRemember = $('p.login-remember'),
            rememberInput = formRemember.find('input#rememberme');

        $('.form-footer .left').append(formRemember);
        $('.form-footer .left p.login-remember').prepend(rememberInput);
        formRemember.addClass('checkbox');
        formRemember.find('label').attr('for', 'rememberme');
        $('.form-footer .right').append('<a href="/wp-login.php?action=lostpassword">Forgot or Want to Change Password?</a>');


        //----------------------------
        // Is Tools Right For Me?
        //----------------------------

        // Restart questionnaire on modal load.
        $('#toolsModal').on('show.bs.modal', function () {
          $('.option-slide.active').removeClass('active');
          $('.option-slide:first-child').addClass('active');
        });

        $('#option-slides .option-slide:first-child').addClass('active');
        $('#option-slides .option-slide').each(function(){
          var listOption = $(this).find('ul li');
            listOption.click(function(){
              var slideHeight = $(this).parent().parent().outerHeight(),
                  slideTarget = $(this).attr('data-target');
              $('.option-slide.active').removeClass('active');
              $('.option-slide#option-' + slideTarget).addClass('active');
              $(".modal").animate({ scrollTop: 150 }, 300);
              // $('#option-slides').height(slideHeight);

           });
        });

        // Nav toggle button
        $('.nav-toggle').click(function() {
          $('.header-right').toggleClass('visible');
        });

        // Map filter
        $('#wpgmza_filter_1, .wpgmza_map').delay(2000).wrapAll('<div class="map-filter"></div>');
        $('#wpgmza_filter_1').delay(2000).each(function(){
          $(this).contents().first().wrap('<span></span>');
          $(this).find('span').html('Filter Locations');
          var mapFilter = $(this);
          $(this).addClass('moved');
          $('.map-filter').append(mapFilter);
        });

        // Truncate/expand team bios
        $('.bio .short').succinct({
            size: 300
        });
        $('.bio .more').click(function(event){
          event.preventDefault();
          $(this).parent().toggleClass('expand');
        });

        // Keep elements the same height.
        function sortNumber(a,b)    {
          return a - b;
        }
        function maxHeight() {
          var viewportWidth = $(window).width();
          if (viewportWidth > 767) {
            var heights = [];
            $('.equal').each(function(){
              $(this).css('height', 'auto');
              heights.push($(this).outerHeight());
              heights = heights.sort(sortNumber).reverse();
              $(this).css('height', heights[0]);
            });
          }
          else {
            $('.equal').each(function(){
              $(this).css('height', 'auto');
            });
          }
        }
        $(document).ready(function() {
          maxHeight();
        });
        $(window).resize(maxHeight);

        // eTools
        if ($('.single-etools-content').length) {
          // Trigger click event on Tools download group titles.
          $('.tools-section .group .group-title').click(function(){
            $(this).parent().toggleClass('active');
            equalizeSidebarContent();
          });
        }

        $('ul.resources li').each(function(){
          $(this).find('.question').click(function(){
            $(this).parent().toggleClass('active');
            equalizeSidebarContent();
          });
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // Declare the hero video.
        var heroVideo = $('#hero-video'),
            heroVideoBlock = $('#hero-video-block');
        // Activate the play button.
        $('#hero #play').click(function(){
          heroVideoBlock.show();
          heroVideo[0].player.play();
        });
        // Activate the close button.
        $('#hero .close').click(function(){
          heroVideo[0].player.pause();
          heroVideoBlock.hide();
        });
      },
      finalize: function() {

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'faqs': {
      init: function() {

        $('ul.faqs li').each(function(){
          $(this).find('.question').click(function(){
            $(this).parent().toggleClass('active');
          });
        });

      }
    },
    'events': {
      init: function(){

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
